import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  templateUrl: './bumpermodal.component.html',
  styleUrls: ['./bumpermodal.component.scss'],
})
export class BumperModalComponent {
  @Input() firstname: string;
  @Input() lastname: string;
  @Input() email: string;
  @Input() town: string;
  @Input() error: boolean;
  constructor(private modalCtrl: ModalController) { }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss({
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      town: this.town
    }, 'confirm');
  }
}
