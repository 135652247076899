import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  public GetExternalUsage() {
    return this.http.get<any>(environment.ApiURL+'/setting/GetExternalUsage').toPromise<any>()
  }
}
