import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { AdditionalJob, AppointmentExistsModel, CustomerDetails, CustomerserviceService, FollowUp, HHAOperationModel, HHAServiceModel, JLocation, OSBAdminJob, OSBJob } from '../services/customerservice.service';
import { BehaviorSubject } from 'rxjs';
import { CalendarDay } from '../components/dateselect/dateselect.component';
import { IonContent } from '@ionic/angular';
import { ExistingappointmentsDialog } from '../bits/existingappointmentsdialog/existingappointmentsdialog.component';
import { LocationselectComponent } from '../components/locationselect/locationselect.component';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BumperService } from 'src/services/bumper.service';
import { BumperJob } from 'src/services/bumper.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit{

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(LocationselectComponent) location: LocationselectComponent;
  @ViewChild('payment') paymentDOM: ElementRef;


  scrollToTop() {
    this.content.scrollToTop();
    setTimeout(()=>{
      this.content.scrollToTop();
    }, 250);
  }

  ngOnInit(): void {
    this.http.get('https://alcrveshtambcsaxrded.supabase.co/storage/v1/object/demofiles/getac/settings.json', {headers:{
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjgyMTA1OTcyLCJzdWIiOiIzNzJjNzhmZC02NDdjLTQ5OTItYWU5Yi01YjJlYThhOTU1YjkiLCJlbWFpbCI6Imphc3Blci53ZXN0cmFAaXFtb3RpdmUubmwiLCJwaG9uZSI6IiIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6ImVtYWlsIiwicHJvdmlkZXJzIjpbImVtYWlsIl19LCJ1c2VyX21ldGFkYXRhIjp7fSwicm9sZSI6ImF1dGhlbnRpY2F0ZWQiLCJhYWwiOiJhYWwxIiwic2Vzc2lvbl9pZCI6IjFhY2NhOTA1LTM5YjUtNDVkOS04N2M5LWNjMDBjZGQwMjVlZCJ9.cqEWY3VjoMuUAP5yFL4eAmcE8IbIoDSFp-fD_AIAUAQ'
    }}).subscribe((v:any) => {
      if (v?.osbtitle) {
        document.title = v.osbtitle;
      }
    })
  }

  // Vehicle_details
  // Location
  // Service_selection
  // Date_Time
  // Contact_details
  // StepValueSelected = "Service_selection" 
  StepValueSelected = "Vehicle_details" 
  currency:string;
  // TODO REMOVE THIS COMMENT AppointmentInfoVin: string                = ""
  AppointmentInfoVin: string                = ""
  AppointmentInfoLicensePlate: string       = ""
  AppointmentInfoMileage: string            = ""
  AppointmentInfoSpecification: number      = 0
  AppointmentInfoModelCode: string          = ""
  AppointmentLocation: JLocation|any         = {id:null}
  AppointmentAdditionals: AdditionalJob[]   = []
  AppointmentRecalls: HHAOperationModel[]   = []
  AppointmentService: HHAServiceModel       = null
  AppointmentServiceDiagnose: AdditionalJob = null
  AllFollowUps: FollowUp[]                  = null
  AppointmentFollowUps: FollowUp[]          = []
  AppointmentDateDate: CalendarDay|any      = {date:null,timeslotsForDay:[],dateHugeText: "-"}
  AppointmentDateTime: string               = "-"
  AppexistInfo: AppointmentExistsModel|undefined = undefined;
  AppointmentCustomer: CustomerDetails      = {
    fullname: "",
    phonenumber: "",
    company: "",
    email: "",
    city: "",
    address: "",
    postalcode: "",
    amNote: ""
  }

  Sending = false
  IsFinished = false;
  finalRes:any={id: 55555800}
  IsRtl = false
  IsSmall = this.platform.width() < 992;
  
  //bumper stuff
  use_bumper = -1;
  payment_method = 0;

  carSearchData = {}
  carSearched(event:any) {
    this.carSearchData = event;
    console.log(event);
  }

  gotoStep1() {
    this.scrollToTop();
    this.StepValueSelected = "Vehicle_details";
    this.AppointmentLocation = {id:null}
    this.AppointmentAdditionals = []
    this.AppointmentFollowUps=[]
    this.AppointmentRecalls = []
    this.AppointmentService = null
    this.AppointmentServiceDiagnose = null
    this.AppointmentDateDate = {date:null,timeslotsForDay:[],dateHugeText: "-"}
    this.AppointmentDateTime = "-"
    if (this.AppexistInfo) {
      this.preselLoc = ""
    }
    this.AppexistInfo = undefined;
  }
  
  async gotoStep2() {
    this.AppointmentInfoVin = this.AppointmentInfoVin.toUpperCase();
    if (!this.step2Active())
      return
    if (this.StepValueSelected == "Vehicle_details") {
      this.customerService.GetFollowUps(this.AppointmentInfoVin).then(async fupObject =>{
        fupObject?.forEach(fup=>{
          fup.isSelected=false;
        })
        this.AllFollowUps=fupObject
      })
      this.StepValueSelected = "Vehicle_details";
      
      if (!(await this.checkVin())) {
        this.StepValueSelected = "";
        setTimeout(()=>{
          this.StepValueSelected = "Vehicle_details";
        }, 150)
        return;
      }
     
    }
      
    this.AppointmentDateDate = {date:null,timeslotsForDay:[],dateHugeText: "-"}
    this.AppointmentDateTime = "-"
    this.StepValueSelected = "Service_selection";
    this.scrollToTop();
  }

  gotoStep3() {
    if (!this.step3Active())
      return

    // this.location.selectedServiceDiagnose = this.AppointmentServiceDiagnose
    // this.location.selectedAdditionals = this.AppointmentAdditionals
    // this.location.ngOnInit();
    this.AppointmentDateDate = {date:null,timeslotsForDay:[],dateHugeText: "-"}
    this.AppointmentDateTime = "-"
    this.StepValueSelected = "Location";
    this.scrollToTop();
  }

  gotoStep4() {
    if (!this.step4Active())
    return
    this.scrollToTop();
    this.AppointmentDateDate = {date:null,timeslotsForDay:[],dateHugeText: "-"}
    this.AppointmentDateTime = "-"
    this.StepValueSelected = "Date_Time";
  }

  gotoStep5() {
    if (!this.step5Active())
      return
    this.StepValueSelected = "Contact_details";
    this.scrollToTop();
  }
  
  step2Active(): boolean {
    return this.AppointmentInfoVin.length > 16 && this.AppointmentInfoModelCode != ""
  }
  
  step4Active(): boolean {
    return this.step3Active() && this.AppointmentLocation.id != null
  }

  step3Active(): boolean {
    if (this.AppointmentRecalls.length > 0 && (this.IsAM || this.userID != "")) {
      return this.step2Active();
    }
    if (this.AppointmentService == null) {
      return false
    }
    // this.customerService.SetFollowUps(this.AllFollowUps);

    return this.step2Active() && (this.AppointmentService.kms != "-" || this.AppointmentServiceDiagnose != null || this.AppointmentAdditionals.length > 0 || this.AppointmentFollowUps.length>0)
  }
  
  step5Active(): boolean {
    return this.step4Active() && this.AppointmentDateDate.date != null && this.AppointmentDateTime != "-"
  }

  nextStep() {
    this.scrollToTop();
    if (this.StepValueSelected == "Vehicle_details") { this.gotoStep2() }
    else if (this.StepValueSelected == "Service_selection") { this.gotoStep3() }
    else if (this.StepValueSelected == "Location") { this.gotoStep4() }
    else if (this.StepValueSelected == "Date_Time") { this.gotoStep5() }
  }

  bumperpay(method: "paylater"|"paynow"){
    this.payment_method = +(method=="paylater")+1
    this.setpaymentMethod(this.payment_method);
    this.bumperService.pay(method)
  }

  bumperreset(){
    this.payment_method=-1
    this.scrollToTop();
  }

  setpaymentMethod(method:number){
    this.payment_method=method
    this.bumperService.setPaymentMethod(this.payment_method)
  }

  blockedvins:string[]=undefined
  async checkVin(): Promise<boolean> {
    console.log("checkvin");
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      backdropDismiss: true
    });
    await loading.present();
    if (!this.blockedvins) {
      this.blockedvins = (await this.customerService.GetBlockedVins()).items.map(v => v.block_vin);
    }
    if (this.blockedvins.includes(this.AppointmentInfoVin)) {
      loading.dismiss();
      this.presentBlocked();
      return
    }
    let appexistinfo = await this.customerService.GetAppointmentExists(this.AppointmentInfoVin).then(v => {
      return v
    }).catch(e => {
      console.log(e);
    })
    loading.dismiss();

    if (appexistinfo == null) {
      return false
    } else if (!(appexistinfo as AppointmentExistsModel).appointmentExists) {
      return true
    } else {
      let dialogresult = await this.presentExistingAppointment(appexistinfo as AppointmentExistsModel);
      if(dialogresult?.data?.continue) {
        this.AppexistInfo = appexistinfo as AppointmentExistsModel;
        this.preselLoc = this.AppexistInfo.id.toString().slice(-3);
        return true
      }
      return false
    }
  }

  async presentExistingAppointment(appexistinfo: AppointmentExistsModel, cancontinue=true) {
    const modal = await this.modalController.create({
      component: ExistingappointmentsDialog,
      componentProps: {
        'Appexistinfo': appexistinfo,
        'WithUser': this.userID,
        'CanContinue': cancontinue
      }
    });
    modal.present();
    return modal.onDidDismiss();
  }

  async presentBlocked() {
    console.log("Presenting alert");
    const alert = await this.alertController.create({
      header: this.IsRtl ? 'غير متوفره' : 'Booking not available',
      subHeader: 'Booking is unavailable right now',
      message: this.IsRtl ? 'لا يمكننا حجز موعدكم يرجى التواصل مع الفرع لخدمتكم بشكل أفضل' : 'We cannot book your appointment at this moment, please contact us to see if we can be of service',
      buttons: [
        {text: 'Cancel', role: 'OK' }
      ]
    });
    await alert.present();
  }

  async presentNeedCancel() {
    console.log("Presenting alert");
    const alert = await this.alertController.create({
      header: this.IsRtl ? "الموعد الحالي" : 'Existing appointment',
      subHeader: this.IsRtl ? "لا يمكن الاستمرار" : 'Can not continue',
      message: this.IsRtl ? "تحتاج إلى إلغاء الموعد الحالي  أولاً" : 'You need to cancel the existing appointment first',
      buttons: [
        {text: 'Cancel', role: 'OK' }
      ]
    });
    await alert.present();
  }
  
  async presentAlertLanguage() {
    console.log("Presenting alert");
    const alert = await this.alertController.create({
      header: 'Booking language',
      subHeader: 'Advanced mode only',
      message: 'Which language should be used when contacting the customer?',
      buttons: [
        {text: 'Arabic', role: 'arabic' }, 
        {text: 'English', role: 'english' },
        {text: 'Cancel', role: 'cancel' }
      ]
    });

    await alert.present();
    let res = await alert.onDidDismiss();
    return res.role;
  }

  async completeBooking() {
    this.currency = "USD";//TODO: GET THE CURRENCY FROM THE BACKEND
    this.bumperService.reset(this.AppointmentCustomer, this.IsRtl, this.currency, this.AppointmentInfoLicensePlate)
    if (this.AppexistInfo) {
      let cancelresult = await this.presentExistingAppointment(this.AppexistInfo, false);
      if (!(cancelresult?.data?.didcancel)) {
        this.presentNeedCancel()
        return false;
      } else {
        this.AppexistInfo = undefined;
      }
    }
    let bookinglang = this.translate.currentLang;
    let useEngName = true;
    if (this.IsAM) {
      const asklang = await this.presentAlertLanguage()
      if (asklang == 'arabic') {
        useEngName = false;
        bookinglang = 'ar';
      } else if (asklang == 'english') {
        bookinglang = 'en';
      } else {
        return
      }
    }
    this.Sending = true
    let hhaAutoRemarkStrArray = [];
    let truedateTime = DateTime.fromFormat(this.AppointmentDateDate.date.toFormat("MM/dd/yyyy")+" " +this.AppointmentDateTime, "MM/dd/yyyy HH:mm", {zone: 'utc'});
    let jobs: OSBAdminJob[] = this.AppointmentRecalls.map(r => { 
      hhaAutoRemarkStrArray.push(r.wo_operation_description)
      return {
        operationCode: r.wo_operation_id,
        duration: r.wo_std_time,
        name: r.wo_operation_description,
        customerTooltip: 'recall',
        price: ''
      };
    });
    if (this.AppointmentService != null) {
      if (this.AppointmentService.kms != "-") {
        hhaAutoRemarkStrArray.push(this.AppointmentService.service_eng)
        this.bumperService.addJob(useEngName? this.AppointmentService.service_eng:this.AppointmentService.service_arb, this.AppointmentService.realPrice)
        jobs.push({
          operationCode: this.AppointmentService.realCode,
          duration: this.AppointmentService.realDuration,
          name: this.AppointmentService.service_eng,
          customerTooltip: "",
          price: this.AppointmentService.realPrice
        })
      }
    }
    if (this.AppointmentServiceDiagnose != null) {
      hhaAutoRemarkStrArray.push(this.AppointmentServiceDiagnose.name)
      this.bumperService.addJob(useEngName? this.AppointmentServiceDiagnose.name: this.AppointmentServiceDiagnose.nameAr, this.AppointmentServiceDiagnose.price)
      jobs.push({
        operationCode: this.AppointmentServiceDiagnose.operationCode,
        duration: this.AppointmentServiceDiagnose.duration,
        price: this.AppointmentServiceDiagnose.price,
        customerTooltip: "",
        name: this.AppointmentServiceDiagnose.name
      })
    }
    this.AppointmentAdditionals.forEach(a => {
      hhaAutoRemarkStrArray.push(a.name)
      this.bumperService.addJob(useEngName? a.name: a.nameAr, a.price)
      jobs.push({
        operationCode: a.operationCode,
        duration: a.duration,
        customerTooltip: a.customerTooltip,
        name: a.name,
        price: a.price
      });
    });
    this.AppointmentFollowUps.forEach(fup=>{
      hhaAutoRemarkStrArray.push(fup.name)
      this.bumperService.addJob(useEngName? fup.name: fup.name2, fup.price)
      jobs.push({
        operationCode: "fupCode",
        duration: 10,
        customerTooltip: fup.recommendation,
        name: fup.name,
        price: fup.price
      });
    })
    if(this.use_bumper>0){
      this.bumperService.setInstalments()
      if(this.bumperService.instalments==0){
        this.use_bumper=0;
      }
    }

    this.customerService.NewAppointment({
      // unknown
      appointmentCancelled: false,
      handoverTime: 0,
      brand: "Mazda",
      cancelDate: null,
      creationTextSent: 0,
      reminderTextSent: 0,
      receptionist: "",
      fromCustomer: !this.IsAM,
      withUser: this.userID,
      hhaService: (this.AppointmentService == null ? 'other' : (this.AppointmentService.kms != "-" && this.AppointmentService.kms != "P" ? this.AppointmentService.service_eng : "other")),
      kms: (this.AppointmentService == null ? '0' : (this.AppointmentService.kms != "-" && this.AppointmentService.kms != "P" ? this.AppointmentService.kms : "0")),
      seenFolder: false,
      
      // known
      arrivalTime: Math.round(truedateTime.toSeconds()),
      arrivalTimeChosen: truedateTime.toFormat("dd-MM-yyyy HH:mm"), // HHA ONLY
      customerAddress: this.AppointmentCustomer.address,
      customerCompany: this.AppointmentCustomer.company,
      customerEmail: this.AppointmentCustomer.email,
      customerName: this.AppointmentCustomer.fullname,
      customerPostalCode: this.AppointmentCustomer.postalcode,
      phoneNumber: this.AppointmentCustomer.phonenumber,
      licensePlate: this.AppointmentInfoLicensePlate,
      model: this.AppointmentInfoModelCode,
      vin: this.AppointmentInfoVin,
      locationCode: this.AppointmentLocation.locationCode,
      jobs: jobs,
      language: bookinglang,
      hhaNote: "(IQOSB) " + (this.AppointmentCustomer.amNote ? this.AppointmentCustomer.amNote : ""),
      hhaAutoRemark: hhaAutoRemarkStrArray.join(', '),
      paymentMethod:-1
    }).then(s => {
      this.finalRes = s;
      this.bumperService.bumperObject.order_reference = this.finalRes.id.toString()
      this.IsFinished = true;
      this.Sending = false;
      this.customerService.SetFollowUps(this.AllFollowUps);
    }).catch(async e => {
      this.Sending = false;
      const toast = await this.toastController.create({
        message: 'Your appointment could not be made, please try another timeslot',
        duration: 2000,
        color: "danger"
      });
      toast.present();
      this.gotoStep4()
    })
    console.log("BUMPERT DEBUG: ",this.bumperService.total_amount, this.bumperService.bumperObject.product_description)
  }

  LogoGetter: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  public changeStepper(event) {
    if (event.detail.value == "Vehicle_details") { this.gotoStep1() }
    else if (event.detail.value == "Service_selection") { this.gotoStep2() }
    else if (event.detail.value == "Location") { this.gotoStep3() }
    else if (event.detail.value == "Date_Time") { this.gotoStep4() }
    else if (event.detail.value == "Contact_details") { this.gotoStep5() }
  }

  setLang(lang) {   
    this.translate.use(lang)
  }
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private alertController: AlertController,
    private customerService: CustomerserviceService,
    public bumperService: BumperService,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private http: HttpClient,
    private toastController: ToastController
  ) {
    this.initializeApp();
    // setInterval(x=>{
    //   console.log(this.AllFollowUps)
    // },2000)
  }

  async openFolder() {
    this.customerService.SeenFolder(this.finalRes.id).then(v => {
      console.log(v);
    })
  }

  IsAM = false;
  userID = "";
  preselLoc = "";

  IsDark=true;
  toggleDark() {
    console.log("Toggle Dark Mode");
    this.IsDark=!this.IsDark
    document.body.classList.toggle('dark-mode', this.IsDark);
  }

  amdec(inp:string) {
    if (inp.length <= 1)
      return this.alpha.indexOf(inp[0]).toString();
    else if (inp.length % 2 == 0)
      return this.alpha.indexOf(inp[0]).toString() + this.amdec(inp.substring(1))
    else
      return this.beta.indexOf(inp[0]).toString() + this.amdec(inp.substring(1))
  }

  alpha=["q","e","r","t","u","i","v","o","p","m"]
  beta=["a","s","y","d","z","f","c","h","k","j"]

  setAMUSRLOC() {
    console.log(location.href);
    let _isAm = false;
    let _userId = "";
    let _locationCode = "";
    let halfway = location.href.split("?");
    if (halfway.length == 2) {
      let parts = halfway[1].split("&")
      parts.forEach(p => {
        if (p.startsWith("advancedfeatures"))
        {
          const tframe = DateTime.utc().toMillis() - Number.parseInt(this.amdec(p.substring(17)))
          if (tframe > 0 && tframe < 5000) {
            _isAm = true
          }
        }
        else if (p.startsWith("usrdmsid"))
        {
          _userId = this.amdec(p.substring(9))
        }
        else if (p.startsWith("preloc"))
        {
          _locationCode = p.substring(7)
        }
      })
    }
    this.IsAM = _isAm;
    this.userID = _userId;
    this.preselLoc = _locationCode;
  }

  initializeApp() {
    this.setAMUSRLOC()
    this.bumperService.getSpreadPaymentSetting().then(x=>{
      this.use_bumper= x['spreadPayment']
    }).catch(error => console.log('Error while getting spread payment ',error));
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.translate.onLangChange.subscribe(v => {
      this.IsRtl = v.lang == 'ar';
      if (this.location) {
        if (Object.keys(this.location).includes('IsArabic')) {
          this.location.IsArabic = this.IsRtl
        }
      }
    })
    this.translate.setDefaultLang((this.translate.getBrowserLang() == 'ar'? 'ar' : 'en'));
    this.translate.use((this.translate.getBrowserLang() == 'ar'? 'ar' : 'en'))
    this.platform.resize.subscribe(r => {
      this.setIsSmall()
    })
    this.setIsSmall()
    this.toggleDark();
  }

  setIsSmall() {
    this.IsSmall = this.platform.width() < 992
  }
}
