import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AdditionalJob, FollowUp, CustomerserviceService, HHAOperationModel, HHAServiceModel, ServicesAndAdditionals, FollowUpObject } from '../../services/customerservice.service';
import { VinphoneComponent } from '../vinphone/vinphone.component';
import { DateTime } from 'luxon';

export class PromoService {
  Service_Desc_Eng: string;
  Arabic_Desc: string;
  Car_Line: string;
  Specification: string;
  Campaign_Code: string;
  Price: string;
  Operation_Code: string;
  Std_Time: number;
  Real_Duration: number;
  constructor(Service_Desc_Eng: string, Arabic_Desc: string, Car_Line: string, Specification: string, Campaign_Code: string, Price: string, Operation_Code: string, Std_Time: number, Real_Duration: number) {
    this.Service_Desc_Eng = Service_Desc_Eng;
    this.Arabic_Desc = Arabic_Desc;
    this.Car_Line = Car_Line;
    this.Specification = Specification;
    this.Campaign_Code = Campaign_Code;
    this.Price = Price;
    this.Operation_Code = Operation_Code;
    this.Std_Time = Std_Time;
    this.Real_Duration = Real_Duration;
  }
}

@Component({
  selector: 'app-serviceselect',
  templateUrl: './serviceselect.component.html',
  styleUrls: ['./serviceselect.component.scss'],
})
export class ServiceselectComponent implements OnInit, OnDestroy {

  @ViewChild('promomodal', { static: true }) promomodal;

  @Input() selectedLocationCode: string = "0";
  @Input() selectedLocationId: number = -1;
  @Input() mileage: string = "";
  @Input() selectedVin: string = "";

  @Input() selectedAdditionals: AdditionalJob[] = [];
  @Output() selectedAdditionalsChange = new EventEmitter<AdditionalJob[]>();

  @Input() followUps: FollowUp[] = [];
  @Output() followUpsChange = new EventEmitter<FollowUp[]>();
  @Input() selectedFollowUps: FollowUp[] = [];
  @Output() selectedFollowUpsChange = new EventEmitter<FollowUp[]>();
 
  @Input() includedRecalls: HHAOperationModel[] = [];
  @Output() includedRecallsChange = new EventEmitter<HHAOperationModel[]>();
  
  @Input() selectedService: HHAServiceModel = null
  @Output() selectedServiceChange = new EventEmitter<HHAServiceModel>();
  
  @Input() diagnoseText: string = ""
  @Output() diagnoseTextChange = new EventEmitter<string>();
  
  @Input() diagnoseEnabled: boolean = false
  @Output() diagnoseEnabledChange = new EventEmitter<boolean>();

  @Input() selectedDiagnose = null;
  @Output() selectedDiagnoseChange = new EventEmitter<AdditionalJob>();

  @Input() specificationValue: number = 0;

  ServicesGetter: BehaviorSubject<ServicesAndAdditionals> = new BehaviorSubject<ServicesAndAdditionals>(undefined);
  FollowUpGetter: BehaviorSubject<Array<FollowUp>> = new BehaviorSubject<Array<FollowUp>>(undefined);
  followUpText:string;

  IsArabic = false;
  showFollowUps = true;

  HideOtherMileage = true;
  ClosestBelowMileage = "";
  ClosestAboveMileage = "";

  public devWidth = this.platform.width();

  TranslateSubscription: Subscription

  constructor(private customerService: CustomerserviceService, private translate: TranslateService, private platform: Platform) {
    this.IsArabic = translate.currentLang == 'ar'
    this.devWidth = this.platform.width();
  }

  toggleAdditional(additionals: AdditionalJob[], event) {
    this.selectedAdditionals = additionals.filter(a => a.isSelected)
    this.selectedAdditionalsChange.emit(this.selectedAdditionals)
  }

  toggleFollowUps(followups: FollowUp[], event) {
    this.selectedFollowUps = followups.filter(a => a.isSelected)
    this.selectedFollowUpsChange.emit(this.selectedFollowUps)
    this.followUpsChange.emit(followups)
  }

  ngOnDestroy() {
    if (this.TranslateSubscription) {
      this.TranslateSubscription.unsubscribe()
    }
  }

  public static range="0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  public static CheckFilter(VIN: string, VINFILTERS: string[]): boolean {
    VIN = VIN.toUpperCase();
    for(let v_idx = 0; v_idx < VINFILTERS.length; v_idx++) {
      const filter = VINFILTERS[v_idx];
      var f_index = 0;
      var v_index = 0;
      while(f_index<filter.length) {
        if(filter[f_index]=="[") {
          if(!ServiceselectComponent.range
            .substring(
              ServiceselectComponent.range.indexOf(filter[f_index+1]), 
              ServiceselectComponent.range.indexOf(filter[f_index+3], 
              ServiceselectComponent.range.indexOf(filter[f_index+1])
            )+1)
            .includes(
              VIN.charAt(v_index))
            )
          {
            break;
          }
          f_index+=5;
        } else if (filter[f_index]!="*") {
          if(VIN.charAt(v_index)!=filter[f_index]) {
            break;
          }
          f_index++;
        } else {
          f_index++;
        }
        v_index++;
      }
      if(f_index == filter.length) {
        return true;
      }
    }
    return false;
  }

  // CheckFilter("1F4AE35L49DA88777",["***BN************","**4[9-A]*************"]);

  async ngOnInit() {
    this.IsArabic = this.translate.currentLang == 'ar'
    this.devWidth = this.platform.width();
    this.TranslateSubscription = this.translate.onLangChange.subscribe(v => {
      this.IsArabic = this.translate.currentLang == 'ar'
    })
    const carline = VinphoneComponent.GetCarLine(this.selectedVin)
    let specification = "";
    if (carline == "GL") {
      if (this.specificationValue == 1) {
        specification = "WithTurbo"
      } else {
        specification = "WithoutTurbo"
      }
    } else if (carline == "TB" || carline == "DK") {
      if (this.specificationValue == 1) {
        specification = "4WD"
      } else {
        specification = "2WD"
      }
    }  else if (carline == "BN" || carline == "BM" || carline == "BL" || carline == "BK") {
      if (this.specificationValue == 1) {
        specification = "2.0L"
      } else {
        specification = "1.6L"
      }
    }

    //get follow ups
    this.followUps = await this.customerService.GetFollowUps(this.selectedVin);
    this.followUps.forEach(x=>{
      x.isSelected=false;

    })
    this.FollowUpGetter.next(this.followUps)
    this.followUpsChange.emit(this.followUps)


    this.customerService.ServicesList(-1, carline, specification, this.selectedVin).then(async v => {
      let jobsconfig = await this.customerService.GetJobConfig();

      this.includedRecalls = v.recalls;
      this.includedRecallsChange.emit(v.recalls);

      // apply vin filter on additional jobs!
      v.additionals = v.additionals.filter(additionalJob => (additionalJob.vinFilter+"").length < 16 ? true : ServiceselectComponent.CheckFilter(this.selectedVin, additionalJob.vinFilter.split(",")))

      // sort the services
      let newSelectedAdditionals = []
      v.services.sort((a,b) => Number.parseInt(a.kms)-Number.parseInt(b.kms))

      // was the additional selected before?
      v.additionals = v.additionals.filter(a => jobsconfig.findIndex(j => j[0] == a.id) >= 0);
      v.additionals.forEach((e,i) => {
        if (this.selectedAdditionals.findIndex(l => l.id == e.id) >= 0) {
          v.additionals[i].isSelected = true
          newSelectedAdditionals.push(v.additionals[i]);
        } else {
          v.additionals[i].isSelected = false
        }
      });
      this.selectedAdditionals = newSelectedAdditionals

      if (this.mileage != "") {
        const mileageOncar = Number.parseInt(this.mileage);
        let closestBelowMileage = 0;
        let closestAboveMileage = 9999999999;
        v.services.forEach(s => {
          if (s.kms != null) {
            const kmsnum = Number.parseInt(s.kms)
            if (kmsnum <= mileageOncar && kmsnum >= closestBelowMileage) {
              closestBelowMileage = kmsnum;
            }
            if (kmsnum > mileageOncar && kmsnum < closestAboveMileage) {
              closestAboveMileage = kmsnum;
            }
          }
        })
        this.ClosestAboveMileage = closestAboveMileage.toString();
        this.ClosestBelowMileage = closestBelowMileage.toString();
      }
      
      const emptyservice = {
        kms:"-",
        service_arb: "لاتوجد خدمة",
        service_eng: "No service",
        specification: ""
      };

      while(true) {
        const index = v.services.findIndex(s => s.service_eng == 'No service' || s.service_eng == 'Basic Maintenance Package');
        if (index >= 0) {
          v.services.splice(index, 1);
        } else {
          break;
        }
      }
      if (!this.selectedService) {
        this.selectedService = emptyservice;
        this.selectedServiceChange.emit(this.selectedService);
      }
      v.services.unshift(this.selectedService);
      this.ServicesGetter.next(v);
    })
    this.showFollowUps = this.followUps.filter(x=> x.status=="awaitingresponse" || x.status=="deferred").length>0
  }

  countIfAdditional(a) {
    if (a) {
      return a.findIndex(i => i.isConcern == false) >= 0
    }
    return false
  }

  confirm() {
    this.selectedService = this.ServicesGetter.value.services[1];
    this.selectedServiceChange.emit(this.selectedService);
  }

  compareFn(e1: AdditionalJob|any, e2: AdditionalJob|any): boolean {
    return e1 && e2 ? e1.id == e2.id : e1 == e2;
  }
}