
import { Injectable } from '@angular/core';
import { CustomerDetails } from './customerservice.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BumperService {
  total_amount:number;
  bumperObject: BumperObject;
  instalments: number;

  constructor(private http: HttpClient, private loadingCtrl: LoadingController) {
    this.bumperObject = {
        "amount": "2089.00",
        "preferred_product_type": "paylater",
        "success_url": "http://www.nu.nl",
        "failure_url": "http://www.rtl.nl",
        "currency": "USD",
        "order_reference": "555558001",
        "first_name": "Jeroen",
        "last_name": "van Asselt",
        "email": "-",
        "mobile": "0612345678",
        "product_description": [
            {
                "item": "30000 KM Periodic Maintenance Package ",
                "quantity": "1",
                "price": "650.00"
            },
            {
                "item": "Inspect brakes",
                "quantity": "1",
                "price": "0.00"
            },
            {
                "item": "Refresh AC operation (Clima Fresh)",
                "quantity": "1",
                "price": "85.00"
            },
            {
                "item": "OZONE Complete Interior Hygiene And Odor treatment",
                "quantity": "1",
                "price": "320.00"
            },
            {
                "item": "Engine Flush System",
                "quantity": "1",
                "price": "364.00"
            },
            {
                "item": "Fuel Injector Cleaner",
                "quantity": "1",
                "price": "185.00"
            },
            {
                "item": "Summer AC Campaign 2022",
                "quantity": "1",
                "price": "230.00"
            },
            {
                "item": "Winter Check",
                "quantity": "1",
                "price": "255.00"
            }
        ],
        "town": "Groningen",
        "vehicle_reg": "45-JSX-4",
        "send_email": true,
        "send_sms":true,
        "instalments": "3",
        "flat_number": "",
        "building_name": "",
        "country": "-",
        "county":"-",
        "postcode":"-"
    }
    this.total_amount = 2089;
  }

  async pay(payment_type: "paynow"|"paylater"){
    this.bumperObject.preferred_product_type  = payment_type
    this.bumperObject.instalments             = this.instalments.toString()
    const loading = await this.loadingCtrl.create({
      message: 'Redirecting to Bumper',
    });
    loading.present();
    let result = await this.http.post<string>(environment.ApiURL+'/payment/bumperpay',{"BumperObject":JSON.stringify(this.bumperObject)}).toPromise<any>();
    if(result.success==true){
      loading.dismiss()
      window.open(result.data.redirect_url, "_blank","toolbar=0")
    }else{
      console.log(result)
      loading.dismiss()
    }
  }

  setPaymentMethod(paymentMethod: number){
    return this.http.put(environment.ApiURL+'/payment/changepaymentmethod', {AppointmentId:this.bumperObject.order_reference, PaymentMethod:paymentMethod}).toPromise();
  }

  getSpreadPaymentSetting(){
    return this.http.get(environment.ApiURL+'/payment/usespreadpayment').toPromise();
  }

  setInstalments(){
    this.http.get(environment.ApiURL+'/payment/instalments?amount='+this.total_amount).toPromise().then(x=>{
      this.instalments = x['data']['products'].sort((n1,n2)=>n2['instalments']-n1['instalments'])[0]['instalments']
    });
  }


  reset(appointment: CustomerDetails, isEnglish:boolean, currency: string, licensePlate:string){
    this.total_amount=0;
    let language = isEnglish?"en":"ar";
    this.bumperObject = {
      amount:"",
      preferred_product_type:"paylater",
      success_url:`http://osb.release.iq-motive.com/assets/payment_landing.html?lang=${language}&success=true`,
      failure_url:`http://osb.release.iq-motive.com/assets/payment_landing.html?lang=${language}&success=false`,
      currency:currency,
      order_reference:"",
      first_name:appointment.fullname.split(" ")[0],
      last_name:appointment.fullname.split(" ").slice(1).join(' '),
      email:appointment.email,
      mobile:appointment.phonenumber,
      product_description:[],
      town:appointment.city,
      vehicle_reg:licensePlate,
      send_email:true,
      send_sms:true,
      instalments: "0",
      flat_number:"-",
      building_name:"-",
      country:"-",
      county:"-",
      postcode:"-"
    }
  }

  addJob(job_name: string, price:string){
    let strprice ="0.00";
    if(!isNaN(+price)){
      strprice = parseFloat(price).toFixed(2)
    }
    this.bumperObject.product_description.push({
      item:job_name,
      quantity:"1",
      price:strprice
    })
    this.total_amount+=+price;
    this.bumperObject.amount = `${this.total_amount}`
  }
}

export interface BumperJob{
  item:string;
  quantity:string;
  price:string;
}

export interface BumperObject{
  amount:string,
  preferred_product_type:"paylater"|"paynow",
  success_url:string,
  failure_url:string,
  currency:string,
  order_reference:string,
  first_name:string,
  last_name:string,
  email:string,
  mobile:string,
  product_description: BumperJob[],
  town:string,
  vehicle_reg:string,
  send_email:boolean,
  send_sms:boolean,
  instalments:string,
  flat_number:string,
  building_name:string,
  country:string,
  county:string,
  postcode:string
}
