import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentExistsModel, CustomerserviceService } from '../../services/customerservice.service';

@Component({
  selector: 'app-existingappointmentsdialog',
  templateUrl: './existingappointmentsdialog.component.html',
  styleUrls: ['./existingappointmentsdialog.component.scss'],
})
export class ExistingappointmentsDialog implements OnInit {

  @Input() Appexistinfo: AppointmentExistsModel;
  @Input() WithUser: string;
  @Input() CanContinue: boolean;

  constructor(private customerService: CustomerserviceService, private modalCtrl: ModalController, private alertController: AlertController, public toastController: ToastController, private translate: TranslateService) { }

  ngOnInit() {
    console.log(this.translate.currentLang == 'en');
  }

  continueAppointment() {
    if (!this.CanContinue) {
      return false;
    }
    this.modalCtrl.dismiss({continue: true});
  }

  async cancelAppointment() {
    if (this.WithUser != "") {
      this.customerService.DeleteAppointment(this.Appexistinfo.id, this.WithUser, "99999", this.Appexistinfo.o).then(async v => {
        if (v.error != undefined) {
          const toast = await this.toastController.create({
            message: 'This code is invalid. Please try again',
            duration: 2000,
            color: "warning"
          });
          toast.present();
        } else {
          console.log(v);
          const toast = await this.toastController.create({
            message: 'Your appointment has been cancelled.',
            duration: 2000,
            color: "primary"
          });
          toast.present();
          this.modalCtrl.dismiss({didcancel: true});
        }
      }).catch(async e => {
        console.log(e);
        const toast = await this.toastController.create({
          message: 'Your appointment could not be cancelled.',
          duration: 2000
        });
        toast.present();
        this.modalCtrl.dismiss();
      })
      return;
    }
    let res = await this.customerService.ConfirmCode(this.Appexistinfo.o, this.translate.currentLang == 'en');
    console.log(res);
    if (res != "OK") {
      const toast = await this.toastController.create({
        message: 'We could not send the cancellation code.',
        duration: 2000,
        color: "danger"
      });
      toast.present();
      return
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm!',
      message: 'Please enter the code sent to your mobile number',
      inputs: [
        {
          name: 'codetocancel',
          type: 'text',
          placeholder: 'One time password'
        }
      ],
      buttons: [
        {
          text: 'Go back',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Confirm',
          cssClass: 'redtext',
          handler: (d) => {
            this.customerService.DeleteAppointment(this.Appexistinfo.id, this.WithUser, d.codetocancel, this.Appexistinfo.o).then(async v => {
              
              if (v.error != undefined) {
                const toast = await this.toastController.create({
                  message: 'This code is invalid. Please try again',
                  duration: 2000,
                  color: "warning"
                });
                toast.present();
              } else {
                console.log(v);
                const toast = await this.toastController.create({
                  message: 'Your appointment has been cancelled.',
                  duration: 2000,
                  color: "primary"
                });
                toast.present();
                this.modalCtrl.dismiss({didcancel: true});
              }
            }).catch(async e => {
              console.log(e);
              const toast = await this.toastController.create({
                message: 'Your appointment could not be cancelled.',
                duration: 2000
              });
              toast.present();
              this.modalCtrl.dismiss();
            })
          }
        }
      ]
    });
    alert.present();
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
