import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment';
import { DateTime } from 'luxon';
import { tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CustomerserviceService {

  constructor(private http: HttpClient) { }

  public Logo() {
    return this.http.get<string>(environment.ApiURL+'/customer/logo', {responseType: 'text' as 'json'}).toPromise<string>()
  }
  
  public ConfirmCode(phone: string, isEn: boolean) {
    return this.http.get<string>(environment.ApiURL+'/customer/confirmcode?o=' + phone + '&isEn='+isEn, {responseType: 'text' as 'json'}).toPromise<string>()
  }
  
  public ModelList() {
    return this.http.get<ModelCodeModel[]>(environment.ApiURL+'/customer/modellist').toPromise<ModelCodeModel[]>()
  }
  
  public JobConfig() {
    return this.http.get<number[][]>(environment.ApiURL+'/customer/getjobconfig').toPromise<number[][]>()
  }
  
  public FindCarWithPhone(phone:string) {
    return this.http.get<FindCarModel[]>(environment.ApiURL+'/customer/findcarwithphone?phone='+phone).toPromise<FindCarModel[]>()
  }
  
  public GetOperation(car_line:string, kms: string) {
    return this.http.get<HHAServiceModel>(environment.ApiURL+`/customer/getoperation?car_line=${car_line}&kms=${kms}`).toPromise<HHAServiceModel>()
  }
  
  public GetAppointmentExists(vin:string) {
    return this.http.get<AppointmentExistsModel>(environment.ApiURL+`/customer/appointmentexists?vin=${vin}`).toPromise<AppointmentExistsModel>()
  }
  
  public DeleteAppointment(id:number, withUser: string, code:string, phone: string) {
    const _time = DateTime.utc().toSeconds().toString();
    const left = (Number.parseInt(_time.substr(0,5))*2).toString();
    const right = (Number.parseInt(_time.substring(5))*2).toString();
    return this.http.get<AppointmentDeleteModel>(environment.ApiURL+`/customer/deleteappointment?idVal=${left}.${id*2}.${right}&withUser=${withUser}&code=${code}&o=${phone}`).toPromise<AppointmentDeleteModel>()
  }

  public LocationList() {
    return this.http.get<JLocation[]>(environment.ApiURL+'/customer/locations').toPromise<JLocation[]>()
  }
  
  public GetTimeslots(param:OSBSlotParameters, url:string) {
    return this.http.post<string[]>(
      url,
      param
    ).toPromise<string[]>()
  }
  
  public NewAppointment(appointment:OSBAppointment) {
    return this.http.post<string>(
      environment.ApiURL+'/customer/newappointment',
      appointment
    ).toPromise<string>()
  }
  
  public SeenFolder(id:number) {
    return this.http.post<any>(
      environment.ApiURL+'/customer/seenfolder',
      {id: id}
    ).toPromise<any>()
  }
  
  cache = new Map<string, ServicesAndAdditionals>();
  public ServicesList(locationId: number, carline: string, specification: string, vin: string) {
    const url = environment.ApiURL+`/customer/services?locationid=${locationId}&carline=${carline}&specification=${specification}&vin=${vin}`;
    if (this.cache.has(url)) {
      console.log("SERVED FROM CACHE")
      return new Promise<ServicesAndAdditionals>((r => {
        r(this.cache.get(url));
      }))
    }
    return this.http.get<ServicesAndAdditionals>(url).pipe(tap(x => {
      console.log(x)
      this.cache.set(url, x)
    })).toPromise<ServicesAndAdditionals>()
  }
  
  public GetJobConfig() {
    const url = environment.ApiURL+`/customer/getjobconfig`;
    return this.http.get<number[][]>(url).toPromise<number[][]>()
  }

  public GetBlockedVins() {
    return this.http.get<BlockedVins>(environment.ApiURL+'/customer/blockedvins').toPromise<BlockedVins>();
  }
}

export interface AppointmentExistsModel
{
  id: number
  appointmentExists: boolean,
  arrivalTimeChosen?: string,
  location?: string,
  phone?: string,
  o: string
}

export interface AppointmentDeleteModel
{
  error?: string
}

export interface OSBJob
{
  duration: number;
  code: string;
  customerTooltip?: string;
  price?: string;
}

export interface OSBSlotParameters
{
  month: number;
  year: number;
  fromCustomer: boolean;
  timeCap: number;
  slotMinutes: number;
  specificReceptionistId: string;
  jobs: OSBJob[]
}

export interface PlanBlock {
  date: string,
	timeslots: string[]
}

// HHA ONLY
export interface ModelCodeModel {
  modelCode: string;
  modelLine: string;
  modelGroup: string;
}

export interface FindCarModel {
  vin: string;
  brand: string;
  model_Group: string;
  model: string;
  licensePlate: string;
}

export interface JLocation {
  id: number;
  geo: string;
  brands: string;
  photo: string;
  locationCode: string;
  locationName: string;
  locationNameAr: string;
  apiUrlAvailability: string;
  apiUrlFinalStep: string;
  locationAddress: string;
  locationAddressAr: string;
  additionalJobs?: AdditionalJob[];
  distance?: number;
  timeCap:number;
  slotMinutes: number;
  isPromoted: boolean;
  isPinOnly: boolean;
}

export interface AdditionalJob {
  id?: number;
  operationCode: string;
  duration: number;
  name: string;
  nameAr: string;
  customerTooltip: string;
  price: string;
  vinFilter: string;
  isConcern: boolean;
  locations?: JLocation[];
  isSelected?: boolean;       // used by checkboxes in serviceselect component
}

export interface CustomerDetails {
  fullname: string;
  phonenumber: string;
  company: string;
  email: string;
  city: string;
  address: string;
  postalcode: string;
  amNote: string;
}

export interface HHAOperationModel {
    wo_operation_id : string;
    wo_operation_description : string;
    wo_std_time : number;
}

export interface ServicesAndAdditionals {
  additionals: AdditionalJob[];
  services: HHAServiceModel[];
  recalls: HHAOperationModel[];
}

export interface HHAServiceModel {
  carLine?: string;
  service_eng?: string;
  service_arb?: string;
  kms?: string;
  realCode?:string;
  realDuration?:number;
  realPrice?:string;
  specification:string;
}

export interface OSBAppointment {
  phoneNumber: string;
  vin: string;
  brand: string;
  model: string;
  licensePlate: string;
  customerName: string;
  customerCompany: string;
  customerEmail: string;
  customerPostalCode: string;
  customerAddress: string;
  seenFolder: boolean;
  arrivalTime: number;
  arrivalTimeChosen?: string;
  handoverTime: number;
  receptionist: string;
  jobs: OSBAdminJob[];
  locationCode: string;
  creationTextSent: number;
  reminderTextSent: number;
  appointmentCancelled: boolean;
  cancelDate: number;
  fromCustomer: boolean;
  hhaService: string;
  kms: string;
  withUser: string;
  language: string;
  hhaNote: string;
  hhaAutoRemark: string;
  paymentMethod:number;
}

export interface OSBAdminJob  {
  operationCode: string;
  duration: number;
  name: string;
  customerTooltip: string;
  price: string;
}

export interface BlockedVins {
  items: {
      block_vin: string;
  }[];
  first: {
      $ref: string;
  };
}