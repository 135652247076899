import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { BehaviorSubject, timer } from 'rxjs';
import { CustomerserviceService, FindCarModel } from '../../../services/customerservice.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-carsearch',
  templateUrl: './carsearch.component.html',
  styleUrls: ['./carsearch.component.scss'],
})
export class CarsearchComponent implements OnInit {

  @Input() phone: string;
  @Input() protect: boolean;

  @Input() authorized: boolean;
  unlocked=false
  mobileStr = "";
  code=""

  carsList: FindCarModel[]=[];
  labelFlipper: any;
  timerSubscription: any;
  loader: any;
  
  constructor(private customerService: CustomerserviceService, 
    private modalCtrl: ModalController, 
    private translateS:TranslateService,
    private toastC: ToastController,
    private loadingController: LoadingController) { }

  ngOnInit() {
    this.mobileStr = this.translateS.instant("This code was send to",{phone:this.phone})
    if(!this.protect){
      this.authorized=true;
      this.unlocked=true;
      this.findCar()
    }else{
      this.sendCode()
    }
  }


  async startLoader() {
    this.loader = await this.loadingController.create({
      backdropDismiss: false,
      mode: 'md',
      message: 'Starting search'
    });
    await this.loader.present();
    const messages = ['One moment please', 'We are fetching the data', 'Busy getting your info', 'Loading'];
    this.timerSubscription = timer(1000,2000).subscribe(t => {
      this.loader.message = messages[t % messages.length];
    });
  }

  stopLoader() {
    this.timerSubscription?.unsubscribe();
    this.loader?.dismiss();
  }


  async findCar(){
    const wt  = [["fl_1",1000],['fl_2',2000],['fl_3',100],['fl_4',1200],['fl_5',2003]]
    await this.startLoader();
    if(!this.authorized){
      await this.customerService.FindSecureCarWithToken(this.phone.replace(/ /g, ""),this.hashPhone(), this.code).then(async v => {
        if(v.access_granted){
          this.unlocked=true
          this.carsList = v.findcarmodel
        }else{
          this.unlocked=false
          const toast = await this.toastC.create({
            message: this.translateS.instant("Wrong code was submitted"),
            duration: 3000,
            color: "danger"
          });
          toast.present();
        }
      })
      this.stopLoader();
    }else{
      await this.customerService.FindCarWithToken(this.phone.replace(/ /g, "")).then(async v=>{
        this.unlocked=true
        this.carsList = v
      });
      this.stopLoader();
    }
  }

  hashPhone(){
    const alphabet = "JasperWTXY";
    return this.phone.split("").map(x=>alphabet[+x]).join("")
  }

  sendCode(){
    this.customerService.ConfirmCode(this.hashPhone(),this.translateS.currentLang=='en');
  }

  selCar(car) {
    this.modalCtrl.dismiss(car);
  }

  close() {
    this.modalCtrl.dismiss();
    this.timerSubscription?.unsubscribe();
    this.loader?.dismiss();
  }


}
