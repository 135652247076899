import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { VinphoneComponent } from '../components/vinphone/vinphone.component';
import { CallusComponent } from '../bits/callus/callus.component';
import { BumperModalComponent } from 'src/bits/bumpermodal/bumpermodal.component';
import { LocationselectComponent } from '../components/locationselect/locationselect.component';
import { ServiceselectComponent } from '../components/serviceselect/serviceselect.component';
import { DateselectComponent } from '../components/dateselect/dateselect.component';
import { ContactdetailsComponent } from '../components/contactdetails/contactdetails.component';
import { CarsearchComponent } from '../components/vinphone/carsearch/carsearch.component';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { FormsModule } from '@angular/forms';
import { ExistingappointmentsDialog } from '../bits/existingappointmentsdialog/existingappointmentsdialog.component';
import { RecommendedlocationComponent } from '../components/recommendedlocation/recommendedlocation.component';
import { DigitOnlyDirective } from '../digitonly.directive';

@NgModule({
    declarations: [
        AppComponent,
        VinphoneComponent,
        CallusComponent,
        LocationselectComponent,
        ServiceselectComponent,
        DateselectComponent,
        ContactdetailsComponent,
        CarsearchComponent,
        ExistingappointmentsDialog,
        RecommendedlocationComponent,
        DigitOnlyDirective,
        BumperModalComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient] // <--- add this
            } // <--- add this
        }),
        IonicModule.forRoot()
    ],
    providers: [
        StatusBar,
        SplashScreen,
        Geolocation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
