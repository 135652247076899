import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as mapboxgl from "mapbox-gl"
import { BehaviorSubject, Subscription } from 'rxjs';
import { AdditionalJob, CustomerserviceService, JLocation } from '../../services/customerservice.service';
import { RecommendedlocationComponent } from '../recommendedlocation/recommendedlocation.component';

@Component({
  selector: 'app-locationselect',
  templateUrl: './locationselect.component.html',
  styleUrls: ['./locationselect.component.scss'],
})
export class LocationselectComponent implements OnInit, AfterViewInit {

  LocationGetter: BehaviorSubject<JLocation[]> = new BehaviorSubject<JLocation[]>(undefined); 

  @Input() preSelectLocationCode = ""

  @Input() selectedServiceDiagnose: AdditionalJob|undefined = undefined
  @Input() selectedAdditionals: AdditionalJob[] = []
  @Input() selectedLocation: any|JLocation = {id:null,locationName:"",locationAddress:""};
  @Output() selectedLocationChange = new EventEmitter<any|JLocation>();
  @Output() goNext = new EventEmitter<any>();

  map: mapboxgl.Map = undefined;

  IsArabic = false;

  constructor(private geolocation: Geolocation, private translate: TranslateService, private customerService: CustomerserviceService, private toastController: ToastController, private modalController: ModalController) {
  }

  TranslateSubscription: Subscription
  ngOnDestroy() {
    if (this.TranslateSubscription) {
      this.TranslateSubscription.unsubscribe()
    }
  }

  ngOnInit() {
    this.IsArabic = this.translate.currentLang == 'ar'
    this.TranslateSubscription = this.translate.onLangChange.subscribe(v => {
      this.IsArabic = this.translate.currentLang == 'ar'
    })
  }

  IAMHIDDEN = true;
  DIDPOPUP = false
  calculateVisibilityHidden(shouldHide: boolean) {
    if (this.IAMHIDDEN != shouldHide) {
      this.IAMHIDDEN = shouldHide;
      if (!shouldHide) {
        if (this.map != undefined) {
          setTimeout(()=>this.map.resize(),100)
          setTimeout(()=>this.map.resize(),200)
          setTimeout(()=>this.map.resize(),500)
          setTimeout(()=>this.map.resize(),2000)
          setTimeout(()=>{
            this.map.resize();
          },1000)
        }
      }
    }
    return shouldHide;
  }

  async presentPopup() {
    if (this.LocationGetter.value) {
      const promlocation = this.LocationGetter.value.find(l => l.locationCode == this.promotedlocationCode);
      if (promlocation) {
        let mdl = await this.modalController.create({component: RecommendedlocationComponent, componentProps: {
          IsArabic: this.IsArabic,
          location: promlocation
        }})
        mdl.present();
        let data = await mdl.onDidDismiss();
        if (data.data) {
          this.setLocationSelected(promlocation)
          setTimeout(()=>{
            this.goNext.emit(0);
          }, 500)
        }
      }
    }
  }

  getDistanceGeos(lon1, lat1, lon2, lat2) {
    const R = 6371e3; // meter
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return R * c; // in meter
  }

  ngAfterViewInit() {
    this.customerService.JobConfig().then(jf => {
      // get job and location map
      var jobLocationConf = new Map<number,number[]>();
      jf.forEach(a => {
        if (!jobLocationConf.has(a[1])) {
          jobLocationConf.set(a[1], [])
        }
        jobLocationConf.set(a[1], [...jobLocationConf.get(a[1]), a[0]]);
      })

      // get locations
      this.customerService.LocationList().then(v => {
        console.log(v.length + " locations before filter.")

        // get included additionals array
        var additionalidarray = [];
        if (this.selectedServiceDiagnose) {
          additionalidarray.push(this.selectedServiceDiagnose.id)
        }
        this.selectedAdditionals.forEach(a => { additionalidarray.push(a.id) });

        // filter locations and continue initialisation
        this.LocationGetter.next(v.filter(l => {
          if (additionalidarray.length == 0) {
            return true
          }
          if (jobLocationConf.has(l.id)) {
            for(let i = 0; i < additionalidarray.length; i++) {
              if (!jobLocationConf.get(l.id).includes(additionalidarray[i])) {
                return false;
              }
            }
            return true;
          }
          return false
        }))
        setTimeout(()=>{
          this.initMap()
        },2000)
      })
    })

  }

  myLocationOnMap = false;

  setMyLocation() {
    if (this.map == undefined) {
      return;
    }
    this.geolocation.getCurrentPosition().then((resp) => {

      if (!this.myLocationOnMap) {
        var el = document.createElement('div');
        el.className = 'mylocation';
        
        // add marker to map
        new mapboxgl.Marker(el)
        .setLngLat([resp.coords.longitude, resp.coords.latitude])
        .addTo(this.map);

        this.myLocationOnMap = true
      }

      this.moveMap(resp.coords.longitude, resp.coords.latitude, 9)
      this.storeDistances(resp.coords.longitude, resp.coords.latitude)
     }).catch((error) => {
       console.log('Error getting location', error);
     });
  }

  moveMap(lon, lat, zoom=12) {
    if (!this.map) {
      console.log("MAP did not load yet.")
      return;
    }
    this.map.flyTo({
      center: [lon, lat],
      zoom: zoom,
      speed: 1.5,
      curve: 1,
    });
  }

  promotedlocationName=""
  promotedlocationNameAr=""
  promotedlocationCode=""

  async storeDistances(lon2, lat2) {
    this.promotedlocationName=""
    this.promotedlocationCode=""
    this.LocationGetter.value.forEach((v,i) => {
      const lonlat1 = v.geo.split(',');
      const distance = this.getDistanceGeos(lonlat1[0],lonlat1[1], lon2, lat2);
      this.LocationGetter.value[i].distance = distance;
      if (distance < 50000 && v.isPromoted) {
        this.promotedlocationName = v.locationName
        this.promotedlocationNameAr = v.locationNameAr
        this.promotedlocationCode = v.locationCode;
      }
    });
    if (!this.DIDPOPUP) {
      this.DIDPOPUP = true;
      this.presentPopup();
    }
    this.LocationGetter.value.sort((a, b) => (a.locationCode == this.promotedlocationCode ? -1 : a.distance) - (b.locationCode == this.promotedlocationCode ? -1 : b.distance))
    this.LocationGetter.next(this.LocationGetter.value)
  }

  formatDistance(distance) {
    return distance < 1500 ? Math.round(distance) + " m" : (Math.round(distance/100)/10) + " km"
  }

  async setLocationSelected(location: JLocation) {
    const coords = location.geo.split(',')
    this.moveMap(coords[0], coords[1])
    this.selectedLocation = location
    this.selectedLocationChange.emit(location)
    const toast = await this.toastController.create({
      message: location.locationName + ", " + location.locationAddress,
      duration: 2000,
      color: "primary"
    });
    toast.present();
  }

  initMap() {

    if (!document.getElementById("MAPBOXCONTAINER")) {
      console.log("MAPBOXCONTAINER already left the screen.")
      return;
    }

    mapboxgl.accessToken = 'pk.eyJ1IjoiamFzcGVyd2VzdHJhMSIsImEiOiJjanc5YTk4bXgwNTVsM3lwbW05aXJieHlwIn0.O257Jhk28oNFRhWZXnD5Og';

    this.map = new mapboxgl.Map({
      container: 'MAPBOXCONTAINER',
      center: [45.307413432742294, 24.068675226853006],
      zoom: 4,
      style: 'mapbox://styles/mapbox/streets-v11'
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    let preloc = null;
    this.LocationGetter.value.forEach((loc) => {
      // create a DOM element for the marker
      var el = document.createElement('div');
      el.className = 'marker';

      el.addEventListener('click', () => {
        this.setLocationSelected(loc)
      });

      // add marker to map
      new mapboxgl.Marker(el)
        .setLngLat(loc.geo.split(','))
        .addTo(this.map);

      if (loc.locationCode == this.preSelectLocationCode && this.preSelectLocationCode != "") {
        preloc = loc;
      }
    });

    setTimeout(()=>{
      console.log("here§")
      console.log(this.selectedLocation)
      console.log(preloc)
      if (this.selectedLocation?.id == null) {
        if (preloc != null) {
          this.setLocationSelected(preloc)
          console.log("selecting " + this.preSelectLocationCode)
        } else {
          this.setMyLocation()
        }
      }
    }, 500);
  }

}