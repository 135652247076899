import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { CustomerserviceService, FindCarModel, ModelCodeModel } from '../../services/customerservice.service';
import { CarsearchComponent } from './carsearch/carsearch.component';
import { SettingsService } from 'src/services/settings.service';

@Component({
  selector: 'app-vinphone',
  templateUrl: './vinphone.component.html',
  styleUrls: ['./vinphone.component.scss'],
})
export class VinphoneComponent implements OnInit, OnDestroy {
  dms:any={name:"",protect:true};
  password:string="";
  selectedCar=null;  

  @Input() vin: string = "";
  @Output() vinChange = new EventEmitter<string>();

  @Input() phone: string;
  @Output() phoneChange = new EventEmitter<string>();

  @Input() mileage: string;
  @Output() mileageChange = new EventEmitter<string>();

  @Input() licensePlate: string;
  @Output() licensePlateChange = new EventEmitter<string>();
  
  @Input() specificationValue: number = 0;
  @Output() specificationValueChange = new EventEmitter<number>();

  @Input() selectedModel = ""
  @Output() selectedModelChange = new EventEmitter<string>();

  constructor(private customerService: CustomerserviceService, 
    private settingsService: SettingsService,
    private modalController: ModalController,
    private toastController: ToastController) { 
    this.settingsService.GetExternalUsage().then(x=>{
      this.dms = x
    });

  }

  public static Modelcodes: ModelCodeModel[] = [
    { modelCode: "Mazda 3",      modelLine: "BK", modelGroup: "MAZDA3" },
    { modelCode: "Mazda 3",      modelLine: "BL", modelGroup: "MAZDA3" },
    { modelCode: "Mazda 3",      modelLine: "BM", modelGroup: "MAZDA3" },
    { modelCode: "Mazda 3",      modelLine: "BN", modelGroup: "MAZDA3" },
    { modelCode: "Mazda 6",      modelLine: "GG", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "GY", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "GH", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "HP", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "HZ", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "GJ", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "GL", modelGroup: "MAZDA6" },
    { modelCode: "Mazda CX 9",   modelLine: "TB", modelGroup: "CX-9" },
    { modelCode: "Mazda CX 9",   modelLine: "TC", modelGroup: "CX-9" },
    { modelCode: "Mazda CX 3",   modelLine: "DK", modelGroup: "CX-3" },
    { modelCode: "Mazda CX 30",  modelLine: "DM", modelGroup: "CX-30" },
    { modelCode: "Mazda CX 5",   modelLine: "KE", modelGroup: "CX-5" },
    { modelCode: "Mazda CX 5",   modelLine: "KF", modelGroup: "CX-5" },
    { modelCode: "Mazda 2",       modelLine: "DE", modelGroup: "MAZDA2" },
    { modelCode: "Mazda MX-5",   modelLine: "NB", modelGroup: "MX-5" },
    { modelCode: "Mazda MX-5", modelLine: "NC", modelGroup: "MX-5"}, 
    { modelCode: "Mazda MX-5",   modelLine: "ND", modelGroup: "MX-5" },
    { modelCode: "Mazda CX-7",   modelLine: "ER", modelGroup: "CX-7" },
    { modelCode: "Mazda BT-50",  modelLine: "UN", modelGroup: "BT-50" },
    { modelCode: "Mazda BT-50",  modelLine: "UP", modelGroup: "BT-50" },
  ]

  CARLINE = "";

  ngOnDestroy() {
    console.log("destro");
    this.convertPhone()
  }

  convertPhone() {
    let newPhone = "";
    for (let i = 0; i < this.phone.length; i++) {
      switch(this.phone[i]) {
        case '٠':
          newPhone += '0'
          break;
        case '١':
          newPhone += '1'
          break;
        case '٢':
          newPhone += '2'
          break;
        case '٣':
          newPhone += '3'
          break;
        case '٤':
          newPhone += '4'
          break;
        case '٥':
          newPhone += '5'
          break;
        case '٦':
          newPhone += '6'
          break;
        case '٧':
          newPhone += '7'
          break;
        case '٨':
          newPhone += '8'
          break;
        case '٩':
          newPhone += '9'
          break;
        default:
          newPhone += this.phone[i];
      }
    }
    this.phone = newPhone;
    this.phoneChange.emit(newPhone);
  }

  public static GetModelCode(vin: string): string {
    const line = vin.substr(3,2).toUpperCase();
    const idx = VinphoneComponent.Modelcodes.findIndex(m => m.modelLine == line);
    return idx < 0 ? "" : VinphoneComponent.Modelcodes[idx].modelCode
  }
  
  public static GetModelGroup(vin: string): string {
    const line = vin.substr(3,2).toUpperCase();
    const idx = VinphoneComponent.Modelcodes.findIndex(m => m.modelLine == line);
    return idx < 0 ? "" : VinphoneComponent.Modelcodes[idx].modelGroup
  }

  public static GetCarLine(vin: string): string {
    return vin.substr(3,2).toUpperCase();
  }

  modelSelectChange(carLine) {

  }

  vinUpdated(_event: string, infoObj:any={vin:""}){
    infoObj.vin = _event.substring(0,17)
    const line  = VinphoneComponent.GetCarLine(infoObj.vin)
    if (line != '``') {
      const idx = VinphoneComponent.Modelcodes.findIndex(m => m.modelLine == line);
      if (idx >= 0) {
        infoObj.selectedModel = VinphoneComponent.Modelcodes[idx].modelCode
      }
    }
    this.selectedModelChange.emit(infoObj.selectedModel);
    this.vinChange.emit(infoObj.vin)

    return infoObj
  }

  updateCarData(_event: any) {
    let infoObj             ={vin:"", specificationValue:0, selectedModel:"", licensePlate:""}
    if(this.dms.name=="HHA"){
      infoObj = this.vinUpdated(_event.vin, infoObj)
    }else{
      infoObj.vin = _event.vin
      infoObj.selectedModel = _event.model
      infoObj.licensePlate = _event.licensePlate;

    }
    this.vin = infoObj.vin;
    this.specificationValue = infoObj.specificationValue
    this.selectedModel = infoObj.selectedModel

    this.vinChange.emit(infoObj.vin)
    this.specificationValueChange.emit(infoObj.specificationValue);
    this.selectedModelChange.emit(infoObj.selectedModel);
    this.licensePlateChange.emit(infoObj.licensePlate)
  }

  //haynes routine
  findCar(){
    this.customerService.FindCarWithToken(this.licensePlate+"Ә"+this.password).then(v => {
      this.vinUpdated(v[0].vin)
      this.licensePlate = v[0].licensePlate;
      this.licensePlateChange.emit(v[0].licensePlate)
      this.selectedModel = v[0].model
      this.selectedModelChange.emit(v[0].model); 
      this.selectedCar=v[0]
    }).catch(async x=>{
      const toast = await this.toastController.create({
        message: x.status==401?'Unauthorized to search cars.':'Car was not found in the system.',
        duration: 2000,
        color: "danger"
      });
      toast.present();
    })
  }

  async searchCar() {
    this.convertPhone();
    const modal = await this.modalController.create({
      component: CarsearchComponent,
      componentProps: {
        'phone': this.phone,
        'protect': this.dms.protect
      }
    });
    modal.present();
    modal.onDidDismiss().then(v => {
      if (v.data) {
        let car = v.data as FindCarModel
        this.updateCarData(car)
        
      }
    })
  }

  ngOnInit() {
    const line = VinphoneComponent.GetCarLine(this.vin)
    this.CARLINE = line;
  }

}
