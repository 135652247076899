import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { CustomerserviceService, FindCarModel } from '../../../services/customerservice.service';

@Component({
  selector: 'app-carsearch',
  templateUrl: './carsearch.component.html',
  styleUrls: ['./carsearch.component.scss'],
})
export class CarsearchComponent implements OnInit {

  @Input() phone: string;

  CarsGetter: BehaviorSubject<FindCarModel[]> = new BehaviorSubject(undefined);
  
  constructor(private customerService: CustomerserviceService, private modalCtrl: ModalController) { }

  ngOnInit() {
    this.customerService.FindCarWithPhone(this.phone.replace(/ /g, "")).then(v => {
      this.CarsGetter.next(v);
    })
  }

  selCar(car) {
    this.modalCtrl.dismiss(car);
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
