import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CustomerDetails, JLocation } from '../../services/customerservice.service';

@Component({
  selector: 'app-contactdetails',
  templateUrl: './contactdetails.component.html',
  styleUrls: ['./contactdetails.component.scss'],
})
export class ContactdetailsComponent implements OnInit {

  termsAgreed=false

  @Input() selectedLocation: JLocation = null;
  @Input() customerInfo: CustomerDetails = {
    fullname: "",
    phonenumber: "",
    company: "",
    email: "",
    city: "",
    address: "",
    postalcode: "",
    amNote: ""
  }
  @Output() customerInfoChange: EventEmitter<CustomerDetails> = new EventEmitter<CustomerDetails>();

  @Output() completeBooking: EventEmitter<any> = new EventEmitter<any>();

  @Input() IsAM: boolean = false;
  @Input() carSearchData:any = {};

  constructor(private translate: TranslateService, private toastController: ToastController) { }


  TranslateSubscription: Subscription;

  ngOnDestroy() {
    this.TranslateSubscription.unsubscribe()
  }

  IsArabic=false;
  ngOnInit() {
    this.IsArabic = this.translate.currentLang == 'ar'
    this.TranslateSubscription = this.translate.onLangChange.subscribe(v => {
      this.IsArabic = this.translate.currentLang == 'ar'
    })
    if (this.carSearchData.hasOwnProperty('fullName')) {
      this.customerInfo.fullname = this.carSearchData.fullName;
    }
    if (this.carSearchData.hasOwnProperty('company')) {
      this.customerInfo.company = this.carSearchData.company;
    }
    if (this.carSearchData.hasOwnProperty('email')) {
      this.customerInfo.email = this.carSearchData.email;
    }
    if (this.carSearchData.hasOwnProperty('address')) {
      this.customerInfo.address = this.carSearchData.address;
    }
    if (this.carSearchData.hasOwnProperty('postalCode')) {
      this.customerInfo.postalcode = this.carSearchData.postalCode;
    }
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: this.translate.instant(msg),
      duration: 2000,
      color: 'warning'
    });
    toast.present();
  }

  doCompleteBooking() {
    if (this.customerInfo.fullname.length < 2) {
      this.presentToast("Please enter a name");                           // <-- arabic
    } else if (this.customerInfo.phonenumber.length < 9) {
      this.presentToast("Please enter a phone number");                   // <-- arabic
    } else if (!this.termsAgreed) {
      this.presentToast("Please read & accept the terms and conditions"); // <-- arabic
    } else {
      this.completeBooking.emit(1)
    }
  }

}
