import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AdditionalJob, CustomerserviceService, HHAOperationModel, HHAServiceModel, JLocation, OSBSlotParameters, PlanBlock } from '../../services/customerservice.service';

@Component({
  selector: 'app-dateselect',
  templateUrl: './dateselect.component.html',
  styleUrls: ['./dateselect.component.scss'],
})
export class DateselectComponent implements OnInit, OnDestroy {

  @Input() selectedVin: string = "";
  @Input() selectedLocation: JLocation = null;
  @Input() selectedAdditionals: AdditionalJob[] = [];
  @Input() selectedDiagnose: AdditionalJob = null;
  @Input() IsAM: boolean = false;
  
  @Input() includedRecalls: HHAOperationModel[] = [];
  @Input() selectedService: HHAServiceModel = null;

  @Input() chosenDate: CalendarDay|any={date:null,timeslotsForDay:[],dateHugeText: "-"}
  @Output() chosenDateChange = new EventEmitter<CalendarDay|any>();
  @Input() chosenTime= "-"
  @Output() chosenTimeChange = new EventEmitter<string>();

  SmallCalendarThreshold = 500;
  DayOfWeekOrder = [
    // 1-7, Monday is 1, Sunday is 7, per ISO 
    7, 
    1, 
    2, 
    3, 
    4, 
    5, 
    6 
  ]
  DayOfWeekNameOrder = [ 
    // Jasper; when changing; match with DayOfWeekOrder
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  SelectedDate = DateTime.local().setLocale('en');
  CalendarDays: BehaviorSubject<CalendarDay[][]> = new BehaviorSubject<CalendarDay[][]>(undefined);

  CalendarIsSmall: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  Observed: Subscription[] = []

  TimeslotsFromServer: string[] = []

  MaxCountDown = 180;
  CountDown = this.MaxCountDown

  public CountDownText(): string {
    const m = Math.floor(this.CountDown / 60)
    const s = Math.floor(this.CountDown % 60)
    if (m < 1) {
      return s+" seconds"
    }
    return m+" min, " + s + " seconds"
  }

  constructor(platform: Platform, private translate: TranslateService, private customerService: CustomerserviceService) {
    this.CalendarIsSmall.next(platform.width()<this.SmallCalendarThreshold);
    this.Observed.push(platform.resize.subscribe(v => {
      this.CalendarIsSmall.next(platform.width()<this.SmallCalendarThreshold);
    }))
    this.Observed.push(interval(1000).subscribe((val) => { 
      this.CountDown--;
      if (this.CountDown <= 0) {
        this.CountDown = this.MaxCountDown;
        this.chosenDate = {date:null,timeslotsForDay:[],dateHugeText: "-"}
        this.chosenDateChange.emit(this.chosenDate)
        this.chosenTime = "-"
        this.chosenTimeChange.emit("-")
        this.ngOnInit();
      }
    }))
  }

  ngOnInit() {
    this.CalendarDays.next(undefined)
    let param:OSBSlotParameters = {
      fromCustomer: !this.IsAM,
      month: this.SelectedDate.month,
      year: this.SelectedDate.year,
      slotMinutes: this.selectedLocation.slotMinutes,
      specificReceptionistId: "no",
      timeCap: this.selectedLocation.timeCap,
      jobs: this.includedRecalls.map(r => { 
        return {
          code: r.wo_operation_id,
          duration: r.wo_std_time
        };
      })
    }
    if (this.selectedService != null) {
      if (this.selectedService.kms != '-') {
        param.jobs.push({
          code: this.selectedService.realCode,
          duration: this.selectedService.realDuration
        })
      }
    }
    if (this.selectedDiagnose) {
      param.jobs.push({
        code: this.selectedDiagnose.operationCode,
        duration: this.selectedDiagnose.duration
      })
    }
    this.selectedAdditionals.forEach(a => param.jobs.push({
      code: a.operationCode,
      duration: a.duration
    }))
    this.TimeslotsFromServer = []
    this.customerService.GetTimeslots(param, this.selectedLocation.apiUrlAvailability).then(p => {
      this.TimeslotsFromServer = p;
      this.CountDown = this.MaxCountDown
      this.initCalendar();
    })
  }

  ngOnDestroy() {
    this.Observed.forEach(v => {
      v.unsubscribe();
    })
  }

  dateClicked(weekDay:CalendarDay) {
    console.log("Weekday clicked", weekDay);
    if (!weekDay.isBlank && !weekDay.isFull && weekDay.notPast) {
      this.chosenTime = "-"
      this.chosenTimeChange.emit("-")
      this.chosenDate = weekDay;
      this.chosenDateChange.emit(weekDay);
      console.log(weekDay);
    }
  }

  nextMonth() {
    this.SelectedDate = this.SelectedDate.plus({months: 1})
    this.chosenDate = {date:null,timeslotsForDay:[],dateHugeText: "-"}
    this.chosenDateChange.emit(this.chosenDate)
    this.chosenTime = "-"
    this.chosenTimeChange.emit("-")
    this.ngOnInit();
  }

  prevMonth() {
    this.SelectedDate = this.SelectedDate.plus({months: -1})
    this.chosenDate = {date:null,timeslotsForDay:[],dateHugeText: "-"}
    this.chosenDateChange.emit(this.chosenDate)
    this.chosenTime = "-"
    this.chosenTimeChange.emit("-")
    this.ngOnInit();
  }

  getSelectedMonthText() {
    return this.SelectedDate.setLocale(this.translate.currentLang).toFormat('MMMM y');
  }

  initCalendar() {
    let traversingDay = this.SelectedDate.setZone("Europe/Amsterdam").set({day: 1, hour: 23}).setLocale('en')
    let calendarDays: CalendarDay[][] = [[]];
    let week_day_counter = 0;
    let week_num_counter = 0;
    let today = DateTime.local();

    // blanks phase
    for (let i = 0; i < 7; i++) {
      if (traversingDay.weekday == this.DayOfWeekOrder[i]) {
        // done with blanks phase
        break;
      } else {
        calendarDays[week_num_counter].push({
          isBlank: true,
          isFull: false,
          notPast: false,
          date: null,
          dateText: "",
          dateHugeText: "",
          timeslotsForDay: []
        })
        week_day_counter++;
      }
    }

    const riyadh_time = DateTime.local().setZone("Europe/Amsterdam")
    const riyadh_time_comperator = riyadh_time.hour * 60 + riyadh_time.minute;
    // date phase
    const maxDays = traversingDay.daysInMonth;
    for (let i = 0; i < maxDays; i++) {
      if (week_day_counter >= 7) {
        week_day_counter = 0;
        week_num_counter++;
        calendarDays.push([]);
      }
      // check if in osb
      let slotsForDay: string[] = this.TimeslotsFromServer.filter(po => !po.endsWith('00:00:00')).filter(po => po.startsWith(traversingDay.setLocale('en').toFormat("MM/dd/yyyy"))).map(t => t.substr(11,5));
      if (slotsForDay.length < 0) {
        calendarDays[week_num_counter].push({
          isBlank: false,
          isFull: true,
          notPast: false,
          date: traversingDay,
          dateText: traversingDay.setLocale(this.translate.currentLang).day.toString(),
          dateHugeText: traversingDay.setLocale(this.translate.currentLang).toLocaleString(DateTime.DATE_HUGE),
          timeslotsForDay: []
        })
      } else {
        if (traversingDay.day == riyadh_time.day && traversingDay.year == riyadh_time.year && traversingDay.month == riyadh_time.month) {
          slotsForDay = slotsForDay.filter(s => {
            const lr = s.split(':')
            return (Number.parseInt(lr[0]) * 60) + Number.parseInt(lr[1]) >= riyadh_time_comperator;
          })
        }
        calendarDays[week_num_counter].push({
          isBlank: false,
          isFull: slotsForDay.length == 0,
          notPast: traversingDay >= riyadh_time,
          date: traversingDay,
          dateText: traversingDay.day.toString(),
          dateHugeText: traversingDay.setLocale(this.translate.currentLang).toLocaleString(DateTime.DATE_HUGE),
          timeslotsForDay: slotsForDay
        })
      }
      traversingDay = traversingDay.plus({days: 1});
      week_day_counter++;
    }

    // blank phase
    for(let i = week_day_counter; i < 7; i++) {
      calendarDays[week_num_counter].push({
        isBlank: true,
        isFull: false,
        notPast: false,
        date: null,
        dateText: "",
        dateHugeText: "",
        timeslotsForDay: []
      })
    }
    
    //final
    this.CalendarDays.next(calendarDays)
  }

}

export interface CalendarDay {
  isBlank: boolean;
  notPast: boolean;
  isFull: boolean;
  date: DateTime;
  dateText: string;
  dateHugeText: string;
  timeslotsForDay: string[];
}
