import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { JLocation } from '../../services/customerservice.service';

@Component({
  selector: 'app-recommendedlocation',
  templateUrl: './recommendedlocation.component.html',
  styleUrls: ['./recommendedlocation.component.scss'],
})
export class RecommendedlocationComponent implements OnInit {

  @Input('location') location: JLocation;
  @Input('IsArabic') IsArabic: boolean;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }
  
  choose() {
    this.modalCtrl.dismiss("choose");
  }

  formatDistance(distance) {
    return distance < 1500 ? Math.round(distance) + " m" : (Math.round(distance/100)/10) + " km"
  }

}
