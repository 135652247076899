import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { CustomerserviceService, FindCarModel, ModelCodeModel } from '../../services/customerservice.service';
import { CarsearchComponent } from './carsearch/carsearch.component';

@Component({
  selector: 'app-vinphone',
  templateUrl: './vinphone.component.html',
  styleUrls: ['./vinphone.component.scss'],
})
export class VinphoneComponent implements OnInit, OnDestroy {

  @Input() vin: string = "";
  @Output() vinChange = new EventEmitter<string>();

  @Input() phone: string;
  @Output() phoneChange = new EventEmitter<string>();

  @Input() mileage: string;
  @Output() mileageChange = new EventEmitter<string>();

  @Input() licensePlate: string;
  @Output() licensePlateChange = new EventEmitter<string>();
  
  @Input() specificationValue: number = 0;
  @Output() specificationValueChange = new EventEmitter<number>();

  @Input() selectedModel = ""
  @Output() selectedModelChange = new EventEmitter<string>();

  @Output() carSearchChosen = new EventEmitter<Object>();

  constructor(private customerService: CustomerserviceService, private modalController: ModalController) { }

  public static Modelcodes: ModelCodeModel[] = [
    { modelCode: "Mazda 3",      modelLine: "BK", modelGroup: "MAZDA3" },
    { modelCode: "Mazda 3",      modelLine: "BL", modelGroup: "MAZDA3" },
    { modelCode: "Mazda 3",      modelLine: "BM", modelGroup: "MAZDA3" },
    { modelCode: "Mazda 3",      modelLine: "BN", modelGroup: "MAZDA3" },
    { modelCode: "Mazda 6",      modelLine: "GG", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "GY", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "GH", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "HP", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "HZ", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "GJ", modelGroup: "MAZDA6" },
    { modelCode: "Mazda 6",      modelLine: "GL", modelGroup: "MAZDA6" },
    { modelCode: "Mazda CX 9",   modelLine: "TB", modelGroup: "CX-9" },
    { modelCode: "Mazda CX 9",   modelLine: "TC", modelGroup: "CX-9" },
    { modelCode: "Mazda CX 3",   modelLine: "DK", modelGroup: "CX-3" },
    { modelCode: "Mazda CX 30",  modelLine: "DM", modelGroup: "CX-30" },
    { modelCode: "Mazda CX 5",   modelLine: "KE", modelGroup: "CX-5" },
    { modelCode: "Mazda CX 5",   modelLine: "KF", modelGroup: "CX-5" },
    { modelCode: "Mazda 2",       modelLine: "DE", modelGroup: "MAZDA2" },
    { modelCode: "Mazda MX-5",   modelLine: "NB", modelGroup: "MX-5" },
    { modelCode: "Mazda MX-5", modelLine: "NC", modelGroup: "MX-5"}, 
    { modelCode: "Mazda MX-5",   modelLine: "ND", modelGroup: "MX-5" },
    { modelCode: "Mazda CX-7",   modelLine: "ER", modelGroup: "CX-7" },
    { modelCode: "Mazda BT-50",  modelLine: "UN", modelGroup: "BT-50" },
    { modelCode: "Mazda BT-50",  modelLine: "UP", modelGroup: "BT-50" },
  ]

  CARLINE = "";

  ngOnDestroy() {
    console.log("destro");
    this.convertPhone()
  }

  convertPhone() {
    let newPhone = "";
    for (let i = 0; i < this.phone.length; i++) {
      switch(this.phone[i]) {
        case '٠':
          newPhone += '0'
          break;
        case '١':
          newPhone += '1'
          break;
        case '٢':
          newPhone += '2'
          break;
        case '٣':
          newPhone += '3'
          break;
        case '٤':
          newPhone += '4'
          break;
        case '٥':
          newPhone += '5'
          break;
        case '٦':
          newPhone += '6'
          break;
        case '٧':
          newPhone += '7'
          break;
        case '٨':
          newPhone += '8'
          break;
        case '٩':
          newPhone += '9'
          break;
        default:
          newPhone += this.phone[i];
      }
    }
    this.phone = newPhone;
    this.phoneChange.emit(newPhone);
  }

  public static GetModelCode(vin: string): string {
    const line = vin.substr(3,2).toUpperCase();
    const idx = VinphoneComponent.Modelcodes.findIndex(m => m.modelLine == line);
    return idx < 0 ? "" : VinphoneComponent.Modelcodes[idx].modelCode
  }
  
  public static GetModelGroup(vin: string): string {
    const line = vin.substr(3,2).toUpperCase();
    const idx = VinphoneComponent.Modelcodes.findIndex(m => m.modelLine == line);
    return idx < 0 ? "" : VinphoneComponent.Modelcodes[idx].modelGroup
  }

  public static GetCarLine(vin: string): string {
    return vin.substr(3,2).toUpperCase();
  }

  modelSelectChange(carLine) {

  }

  vinUpdated(_event: string) {
    const event = _event.substring(0,17)
    this.vin = event;
    this.vinChange.emit(event)
    this.specificationValue = 0;
    this.specificationValueChange.emit(this.specificationValue);
    const line = VinphoneComponent.GetCarLine(event)
    this.CARLINE = line;
    if (line == '``') {
      this.selectedModel = ""
      this.selectedModelChange.emit(""); 
    } else {
      const idx = VinphoneComponent.Modelcodes.findIndex(m => m.modelLine == line);
      if (idx < 0) {
        this.selectedModel = ""
        this.selectedModelChange.emit(""); 
      } else {
        this.selectedModel = VinphoneComponent.Modelcodes[idx].modelCode
        this.selectedModelChange.emit(VinphoneComponent.Modelcodes[idx].modelCode);
      }
    }
  }

  async searchCar() {
    this.convertPhone();
    const modal = await this.modalController.create({
      component: CarsearchComponent,
      componentProps: {
        'phone': this.phone
      }
    });
    modal.present();
    modal.onDidDismiss().then(v => {
      if (v.data) {
        this.carSearchChosen.emit(v.data)
        let car = v.data as FindCarModel
        this.vinUpdated(car.vin)
        this.licensePlate = car.licensePlate;
        this.licensePlateChange.emit(car.licensePlate)
      }
    })
  }

  ngOnInit() {
    const line = VinphoneComponent.GetCarLine(this.vin)
    this.CARLINE = line;
  }

}
